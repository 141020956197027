import { CRM_URL, LOGISTICS_URL, PORTAL_URL } from '../config/urls';
import Project from '../shared/interfaces/project.interface';
import { Role } from './roles';

const projects: Project[] = [
    {
        name: 'Portal',
        icon: 'fas fa-solar-panel',
        piIcon: 'FaSolarPanel',
        url: PORTAL_URL,
        roles: [],
    },
    {
        name: 'Gestão',
        icon: 'fas fa-cog',
        piIcon: 'PiGear',
        url: CRM_URL,
        roles: [],
    },
    {
        name: 'Logística & Produção',
        icon: 'fas fa-truck',
        piIcon: 'PiTruck',
        url: `${LOGISTICS_URL}/dashboard`,
        roles: [
            Role.Administrator,
            Role.Logistics,
            Role.Commercial,
            Role.CommercialSupervisor,
            Role.CustomerSuccess,
        ],
    },
];

export default projects;
