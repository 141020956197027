/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { API_URL, CRM_URL } from '../../config/urls';

enum HttpMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    PATCH = 'patch',
    DEL = 'delete',
}

interface RequestParams {
    method: HttpMethod;
    path: string;
    data?: Record<string, any>;
    config?: AxiosRequestConfig;
}

interface UseRequestParams {
    baseURL?: string;
    timeout?: number;
}

const fiveMinutesInMilliseconds = 300000;

export default function useRequest({
    baseURL = API_URL,
    timeout = fiveMinutesInMilliseconds,
}: UseRequestParams) {
    const api: AxiosInstance = axios.create({
        baseURL,
        timeout,
        withCredentials: true,
        timeoutErrorMessage:
            'Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.',
    });

    const refreshAuth = async () => {
        const tokenRefreshPath = '/auth/refresh';
        const logoutPath = 'auth/logout';
        const loginUrl = `${CRM_URL}/login`;

        return api
            .post(tokenRefreshPath, { skipAuthRefresh: true })
            .then(() => Promise.resolve())
            .catch(async (error) => {
                const logoutApi: AxiosInstance = axios.create({
                    baseURL: API_URL,
                    timeout: fiveMinutesInMilliseconds,
                    withCredentials: true,
                    timeoutErrorMessage:
                        'Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.',
                });

                await logoutApi
                    .post(logoutPath)
                    .then(() => Promise.resolve())
                    .catch(() => Promise.reject());

                window.location.href = loginUrl;

                return Promise.reject(error);
            });
    };

    createAuthRefreshInterceptor(api, refreshAuth, {
        shouldRefresh: (error) =>
            error?.response?.data?.statusCode === 401 &&
            error?.response?.data?.isTokenExpired,
    });

    function getConfig(config: AxiosRequestConfig): AxiosRequestConfig {
        return {
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
            },
            ...config,
        };
    }

    function getSearchParams(data: Record<string, any>): string {
        const params = Object.keys(data).map((key) => {
            if (Array.isArray(data[key]) && data[key].length) {
                return data[key]
                    .map((param: any, index: number) =>
                        Object.keys(param)
                            .map(
                                (paramKey) =>
                                    `${new URLSearchParams({
                                        [`${key}[${index}][${paramKey}]`]:
                                            param[paramKey],
                                    })}`,
                            )
                            .join('&'),
                    )
                    .join('&');
            }

            if (!Array.isArray(data[key]) && data[key] !== undefined) {
                return `${new URLSearchParams({ [key]: data[key] })}`;
            }

            return '';
        });

        return params.filter((param) => !!param).join('&');
    }

    async function httpRequest<R>({
        method,
        path,
        data,
        config,
    }: RequestParams): Promise<R> {
        const axiosMethod = api[method];
        const reqConfig = getConfig(config || {});

        if ([HttpMethod.GET, HttpMethod.DEL].includes(method)) {
            return axiosMethod(path, reqConfig);
        }

        return axiosMethod(path, data, reqConfig);
    }

    async function request<R>({
        method,
        path,
        data,
        config,
    }: RequestParams): Promise<R> {
        try {
            const response = await httpRequest<R>({
                method,
                path,
                data,
                config,
            });

            return response;
        } catch (error: any) {
            const requestIsCanceled = !error.response;

            if (requestIsCanceled) {
                return Promise.reject(error);
            }

            const { data: errorData } = error.response;

            return Promise.reject(errorData);
        }
    }

    function get<T = any, R = AxiosResponse<T>>(
        pathParam: string,
        data?: Record<string, any>,
        config?: AxiosRequestConfig,
    ): Promise<R> {
        const params = data ? getSearchParams(data) : '';

        const path = `${pathParam}?${params}`;

        return request<R>({ method: HttpMethod.GET, path, config });
    }

    function post<T = any, R = AxiosResponse<T>>(
        path: string,
        data?: Record<string, any>,
        config?: AxiosRequestConfig,
    ): Promise<R> {
        return request<R>({
            method: HttpMethod.POST,
            path,
            data,
            config,
        });
    }

    function put<T = any, R = AxiosResponse<T>>(
        path: string,
        data?: Record<string, any>,
        config?: AxiosRequestConfig,
    ): Promise<R> {
        return request<R>({ method: HttpMethod.PUT, path, data, config });
    }

    function patch<T = any, R = AxiosResponse<T>>(
        path: string,
        data?: Record<string, any>,
        config?: AxiosRequestConfig,
    ): Promise<R> {
        return request<R>({
            method: HttpMethod.PATCH,
            path,
            data,
            config,
        });
    }

    async function del<T = void, R = AxiosResponse<T>>(
        path: string,
        config?: AxiosRequestConfig,
    ): Promise<R> {
        return request<R>({ method: HttpMethod.DEL, path, config });
    }

    return {
        del,
        get,
        put,
        patch,
        post,
    };
}
