/* eslint-disable import/prefer-default-export */
import { Container, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import BaseLayout from '../../components/BaseLayout';
import useLibrariesHttpService from '../../services/http/libraries-http';
import HomeCard from '../../components/Home/HomeCard';
import StyledGrid from './styles';

export function Home() {
    const { getLibraries } = useLibrariesHttpService();

    async function loadLibraries() {
        const { data } = await getLibraries();

        return data.data;
    }

    const { data, isLoading, isFetching } = useQuery(
        ['librariesHome'],
        loadLibraries,
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
            retry: 1,
            retryOnMount: false,
        },
    );

    return (
        <BaseLayout>
            <Container className="mt-5 mb-5">
                <h4>Bem-vindo(a) à Biblioteca</h4>
                {(isLoading || isFetching) && (
                    <Spinner
                        animation="border"
                        className="mr-2 mt-1"
                        size="sm"
                    />
                )}
                {data?.length && (
                    <StyledGrid>
                        {data.map((library) => (
                            <HomeCard key={library.id} library={library} />
                        ))}
                    </StyledGrid>
                )}
            </Container>
        </BaseLayout>
    );
}
