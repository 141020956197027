/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-else-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable consistent-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
import { useEffect, useRef, useState } from 'react';
import {
    Accordion,
    Breadcrumb,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
    Spinner,
} from 'react-bootstrap';
import HtmlParser from 'react-html-parser';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { rowsPerPage } from '../../constants/pagination';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import useLibrariesHttpService from '../../services/http/libraries-http';
import { formatBytes } from '../../utils/text/getFileSize';
import { handleDownloadFile } from '../../utils/download/downloadFile';
import {
    ArrowIconDown,
    ArrowIconUp,
    DownloadIcon,
    DownloadText,
} from './style';
import { LibraryTypeReference } from '../../constants/library';
import getItemFileUrl from '../../utils/getItemFileUrl';

interface RouteParams {
    route: string;
}
interface Props {
    libraryId: number;
    libraryTitle: string;
    libraryDescription: string;
    libraryRoute: string;
}

export function Documentation(props: Props) {
    const {
        getItems,
        getBrandsByItemModule,
        getBrandsCategoriesByItemModule,
        getModulesByLibraryRoute,
    } = useLibrariesHttpService();

    const loadMoreRef = useRef();

    const { route } = useParams<RouteParams>();

    const [loading, setLoading] = useState(false);
    const [term, setTerm] = useState('');
    const [brandOptions, setBrandOptions] = useState<any[]>([]);
    const [itemsExpanded, setItemsExpanded] = useState<number[]>([]);
    const [modulesExpanded, setModulesExpanded] = useState<number[]>([]);
    const [selectedBrands, setSelectedBrands] = useState<number[]>([]);
    const [selectedBrandsCategories, setSelectedBrandsCategories] = useState<
        number[]
    >([]);

    async function loadModules() {
        const { data } = await getModulesByLibraryRoute(props.libraryRoute);

        return data;
    }

    async function loadBrandOptionsByModule() {
        let modulesBrandsAndCategories: any[] = [];

        setLoading(true);

        for (let moduleItem of modules) {
            const brands = await getBrandsByItemModule(moduleItem.id, {
                libraryId: props.libraryId,
                libraryType: LibraryTypeReference.Documentation,
            });

            const categories = await getBrandsCategoriesByItemModule(
                moduleItem.id,
                {
                    libraryId: props.libraryId,
                    libraryType: LibraryTypeReference.Documentation,
                },
            );

            for (let category of categories.data) {
                if (category.brand) {
                    const checkIfBrandAlreadyExists = brands.data.find(
                        (brand: any) => brand.id === category.brand.id,
                    );
                    if (!checkIfBrandAlreadyExists) {
                        brands.data = brands.data.concat(category.brand);
                    }
                }
            }

            for (let brand of brands.data) {
                modulesBrandsAndCategories.push({
                    ...brand,
                    moduleId: moduleItem.id,
                    categories: categories.data.filter(
                        (category: any) => category.libraryBrandId === brand.id,
                    ),
                });
            }
        }

        setModulesExpanded(modules.map((m: any) => m.id));
        setBrandOptions(modulesBrandsAndCategories);
        setLoading(false);
    }

    const { data: modules = [], refetch: modulesRefetch } = useQuery(
        ['libraryModules'],
        loadModules,
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
            retry: 1,
            retryOnMount: false,
        },
    );

    async function loadItems({ pageParam = 0 }: any) {
        const params = {
            term: term ? term : '',
            skip: pageParam,
            take: rowsPerPage,
            libraryType: LibraryTypeReference.Documentation,
            libraryId: props.libraryId,
            brands: selectedBrands.toString(),
            categories: selectedBrandsCategories.toString(),
            moduleIds: modules.map((m: any) => m.id).toString(),
            moduleIsNotNull: true,
        };

        const { data } = await getItems(params);

        return {
            data: data.data,
            currentPage: pageParam,
            pages: Math.ceil(data.meta.total / 5),
        };
    }

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
        refetch,
    } = useInfiniteQuery('itemsByModule', loadItems, {
        getNextPageParam: (response: any) => {
            if (response.currentPage + 1 < response.pages) {
                return response.currentPage + 1;
            }
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
        }, 500);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBrands, selectedBrandsCategories]);

    useEffect(() => {
        if (modules) {
            refetch();

            if (modules.length > 0) {
                loadBrandOptionsByModule();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modules]);

    useEffect(() => {
        modulesRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route]);

    return (
        <Container className={'mt-5 mb-5'}>
            <Breadcrumb className={'mt-5'}>
                <Breadcrumb.Item href="/">Biblioteca</Breadcrumb.Item>
                <Breadcrumb.Item active>{props.libraryTitle}</Breadcrumb.Item>
            </Breadcrumb>
            <Col className={'pl-0 pr-0 mt-5'}>
                <h1>{props.libraryTitle}</h1>
                <h6>{props.libraryDescription}</h6>
            </Col>
            <Row className={'mt-4'}>
                <Col sm={3}>
                    {loading ? (
                        <Col md={12} className="text-center">
                            <Spinner
                                animation="border"
                                className="mr-2 mt-1"
                                size={'sm'}
                            />
                            Carregando filtros...
                        </Col>
                    ) : (
                        <>
                            <InputGroup className="mb-4">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <i className="fas fa-search" />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    value={term}
                                    onChange={(event) =>
                                        setTerm(event.target.value)
                                    }
                                    placeholder="Digite aqui o que procura..."
                                />
                            </InputGroup>
                        </>
                    )}
                    {modules &&
                        modules.map((moduleItem: any, index: number) => {
                            const options = brandOptions.filter(
                                (brandOption) =>
                                    brandOption.moduleId === moduleItem.id,
                            );

                            return (
                                options.length > 0 && (
                                    <Accordion
                                        key={index}
                                        className={'mb-2'}
                                        defaultActiveKey={index.toString()}
                                    >
                                        <Card>
                                            <Accordion.Toggle
                                                as={Card.Header}
                                                eventKey={index.toString()}
                                                onClick={() => {
                                                    if (
                                                        modulesExpanded.includes(
                                                            moduleItem.id,
                                                        )
                                                    ) {
                                                        return setModulesExpanded(
                                                            (
                                                                expand: number[],
                                                            ) =>
                                                                expand.filter(
                                                                    (
                                                                        itemCardId,
                                                                    ) =>
                                                                        itemCardId !==
                                                                        moduleItem.id,
                                                                ),
                                                        );
                                                    } else {
                                                        return setModulesExpanded(
                                                            (
                                                                expand: number[],
                                                            ) =>
                                                                expand.concat(
                                                                    moduleItem.id,
                                                                ),
                                                        );
                                                    }
                                                }}
                                            >
                                                <Row
                                                    className={
                                                        'align-items-center'
                                                    }
                                                >
                                                    <Col>
                                                        {moduleItem.name.toUpperCase()}
                                                    </Col>
                                                    {modulesExpanded.includes(
                                                        moduleItem.id,
                                                    ) ? (
                                                        <ArrowIconUp />
                                                    ) : (
                                                        <ArrowIconDown />
                                                    )}
                                                </Row>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse
                                                eventKey={index.toString()}
                                            >
                                                <Card.Body>
                                                    {options.map(
                                                        (
                                                            brandOption,
                                                            brandOptionIndex,
                                                        ) => {
                                                            const categories =
                                                                brandOption.categories;

                                                            return (
                                                                <Col
                                                                    key={
                                                                        brandOptionIndex
                                                                    }
                                                                >
                                                                    <Row
                                                                        className={`${
                                                                            brandOptionIndex !==
                                                                                0 &&
                                                                            'mt-2'
                                                                        } pl-3`}
                                                                        onClick={() => {
                                                                            if (
                                                                                selectedBrands.includes(
                                                                                    brandOption.id,
                                                                                )
                                                                            ) {
                                                                                setSelectedBrands(
                                                                                    (
                                                                                        selectedBrands,
                                                                                    ) =>
                                                                                        selectedBrands.filter(
                                                                                            (
                                                                                                brandId,
                                                                                            ) =>
                                                                                                brandId !==
                                                                                                brandOption.id,
                                                                                        ),
                                                                                );
                                                                            } else {
                                                                                setSelectedBrands(
                                                                                    (
                                                                                        selectedBrands,
                                                                                    ) =>
                                                                                        selectedBrands.concat(
                                                                                            brandOption.id,
                                                                                        ),
                                                                                );
                                                                                setSelectedBrandsCategories(
                                                                                    (
                                                                                        selectedBrandCategories,
                                                                                    ) =>
                                                                                        selectedBrandCategories.concat(
                                                                                            categories.map(
                                                                                                (
                                                                                                    category: any,
                                                                                                ) =>
                                                                                                    category.id,
                                                                                            ),
                                                                                        ),
                                                                                );
                                                                            }
                                                                        }}
                                                                        style={{
                                                                            lineHeight: 1,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <Form.Check
                                                                            readOnly={
                                                                                true
                                                                            }
                                                                            checked={selectedBrands.includes(
                                                                                brandOption.id,
                                                                            )}
                                                                            type="checkbox"
                                                                        />
                                                                        <h5>
                                                                            {
                                                                                brandOption.name
                                                                            }
                                                                        </h5>
                                                                    </Row>

                                                                    {categories.map(
                                                                        (
                                                                            category: any,
                                                                            categoryIndex: number,
                                                                        ) => {
                                                                            return (
                                                                                <Col
                                                                                    className={
                                                                                        'pl-4'
                                                                                    }
                                                                                    key={
                                                                                        categoryIndex
                                                                                    }
                                                                                >
                                                                                    <Row
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                selectedBrandsCategories.includes(
                                                                                                    category.id,
                                                                                                )
                                                                                            ) {
                                                                                                setSelectedBrandsCategories(
                                                                                                    (
                                                                                                        selectedCategories,
                                                                                                    ) =>
                                                                                                        selectedCategories.filter(
                                                                                                            (
                                                                                                                brandId,
                                                                                                            ) =>
                                                                                                                brandId !==
                                                                                                                category.id,
                                                                                                        ),
                                                                                                );
                                                                                            } else {
                                                                                                setSelectedBrandsCategories(
                                                                                                    (
                                                                                                        selectedCategories,
                                                                                                    ) =>
                                                                                                        selectedCategories.concat(
                                                                                                            category.id,
                                                                                                        ),
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        style={{
                                                                                            lineHeight: 1.4,
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                    >
                                                                                        <Form.Check
                                                                                            readOnly={
                                                                                                true
                                                                                            }
                                                                                            checked={selectedBrandsCategories.includes(
                                                                                                category.id,
                                                                                            )}
                                                                                            type="checkbox"
                                                                                            label={
                                                                                                category.name
                                                                                            }
                                                                                        />
                                                                                    </Row>
                                                                                </Col>
                                                                            );
                                                                        },
                                                                    )}
                                                                </Col>
                                                            );
                                                        },
                                                    )}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                )
                            );
                        })}
                </Col>
                <Col sm={9}>
                    {data &&
                        data?.pages &&
                        data?.pages.map((group: any) =>
                            group?.data.map((item: any, index: any) => (
                                <Accordion key={index} className={'mb-3'}>
                                    <Card>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            eventKey={index.toString()}
                                            onClick={() => {
                                                if (
                                                    itemsExpanded.includes(
                                                        item.id,
                                                    )
                                                ) {
                                                    return setItemsExpanded(
                                                        (expand: number[]) =>
                                                            expand.filter(
                                                                (itemCardId) =>
                                                                    itemCardId !==
                                                                    item.id,
                                                            ),
                                                    );
                                                } else {
                                                    return setItemsExpanded(
                                                        (expand: number[]) =>
                                                            expand.concat(
                                                                item.id,
                                                            ),
                                                    );
                                                }
                                            }}
                                        >
                                            <Row
                                                className={
                                                    'p-2 align-items-center'
                                                }
                                            >
                                                <Col>
                                                    <h4>{item.title}</h4>
                                                    <Row className={'ml-1'}>
                                                        <DownloadText
                                                            onClick={async (
                                                                e,
                                                            ) => {
                                                                e.stopPropagation();
                                                                handleDownloadFile(
                                                                    item.file
                                                                        .fileName,
                                                                    getItemFileUrl(
                                                                        item,
                                                                    ),
                                                                );
                                                            }}
                                                            title={
                                                                item.file
                                                                    .awsFileName
                                                            }
                                                        >
                                                            <DownloadIcon />
                                                            Baixar arquivo (
                                                            {formatBytes(
                                                                item.file
                                                                    .fileSize,
                                                            )}
                                                            )
                                                        </DownloadText>
                                                    </Row>
                                                </Col>
                                                {item.description &&
                                                    (itemsExpanded.includes(
                                                        item.id,
                                                    ) ? (
                                                        <ArrowIconUp />
                                                    ) : (
                                                        <ArrowIconDown />
                                                    ))}
                                            </Row>
                                        </Accordion.Toggle>
                                        {item.description && (
                                            <>
                                                <Accordion.Collapse
                                                    eventKey={index.toString()}
                                                >
                                                    <Card.Body
                                                        style={{
                                                            whiteSpace:
                                                                'break-spaces',
                                                        }}
                                                    >
                                                        {HtmlParser(
                                                            item.description,
                                                        )}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </>
                                        )}
                                    </Card>
                                </Accordion>
                            )),
                        )}
                </Col>
                <Col md={12} className="text-center" ref={loadMoreRef as any}>
                    {(isLoading || isFetchingNextPage) && (
                        <Col md={12} className="text-center">
                            <Spinner
                                animation="border"
                                className="mr-2 mt-1"
                                size={'sm'}
                            />
                            Carregando...
                        </Col>
                    )}
                    {data?.pages[0].data.length === 0 && (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Sem itens para carregar
                            </strong>
                        </Col>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
