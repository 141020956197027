import {
    Button,
    DropdownButton,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
} from 'react-bootstrap';
import styled from 'styled-components';

export const StyledNavbar = styled(Navbar)`
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#516BEB+0,4B0082+100 */
    background: #516beb; /* Old browsers */
    background: -moz-linear-gradient(
        left,
        #516beb 0%,
        #8a2be2 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        #516beb 0%,
        #8a2be2 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        #516beb 0%,
        #8a2be2 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#516BEB', endColorstr='#8A2BE2',GradientType=1 ); /* IE6-9 */

    height: 60px;
    margin-left: -15px !important;
    margin-right: -15px !important;

    a {
        font-size: 12px;
        font-weight: 400;
    }

    .Brand {
        font-size: 22px !important;
        font-weight: 600;
        width: 200px;
    }
`;

export const StyledNavDropdown = styled(NavDropdown)`
    .dropdown-toggle::after {
        display: none;
    }
    .dropdown-toggle::before {
        display: none;
    }
    a {
        color: #707070;
        font-weight: bolder !important;

        :active {
            color: #ffffff;
        }
    }
`;

export const StyledDropdownButton = styled(DropdownButton)`
    .btn-primary {
        background: none;
        border: none;

        ::after {
            content: none;
        }

        :active {
            background: none !important;
            border: none !important;
        }

        :focus {
            background: none !important;
            border: none;

            box-shadow: none;
        }
    }

    :active {
        background: none;
        border: none;
    }
`;

export const StyledNavbarItemDivider = styled(Nav.Item)`
    border-right: 1px solid #ffffff;
    opacity: 0.25;
    margin: -8px;
`;

export const StyledDropdownTitle = styled.span`
    font-weight: bolder;
    font-size: 14px;
    overflow-wrap: break-word;
    word-wrap: break-word;
`;

export const StyledDropdownSubtitle = styled.span`
    font-size: 12px;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
`;

export const StyledTextRole = styled.span`
    font-size: 12px;
    color: #f2994a;
    font-weight: 600;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
`;

export const StyledNavDropdownItem = styled(NavDropdown.Item)`
    font-size: 14px;
    color: #707070;
    a {
        font-weight: normal !important;
    }
`;

export const StyledNavDropdownItemText = styled(NavDropdown.ItemText)`
    font-size: 14px;
    font-weight: 600 !important;
`;

export const StyledNavDropdownTitle = styled(NavDropdown.ItemText)`
    font-size: 16;
    font-weight: 600 !important;
`;

export const StyledNavBlueValueText = styled(NavDropdown.ItemText)`
    font-weight: 600;
    color: #2f81ed;
    font-size: 0.9vw;
    margin-left: 0.4vw;
`;

export const StyledNavValueText = styled(NavDropdown.ItemText)`
    font-weight: 600;
    font-size: 0.9vw;
    margin-left: 0.4vw;
`;

export const StyledNavDropdownText = styled(NavDropdown.ItemText)`
    font-size: 0.8vw;
    color: #707070;
    font-weight: 600;
`;

export const StyledListGroup = styled(ListGroup)`
    overflow: auto;
    height: auto;
    max-height: 200px;
`;

export const StyledNotificationTitle = styled.span`
    font-size: 13px !important;
    font-weight: bolder;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
`;
export const StyledNotificationMessage = styled.p`
    font-size: 13px !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
`;
export const OptionContainer = styled.a`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 10px;
    margin-left: 13px;
    margin-right: 13px;
    &:hover {
        text-decoration: none;
    }
`;

export const IconContainer = styled.div`
    background-color: rgba(80, 80, 80, 0.1);
    border-radius: 100px;
    width: 45px;
    text-align: center;
`;

export const Icon = styled.i`
    font-size: 20px;
    padding: 10px;
    padding-top: 12px;
    color: #836bd9;
`;

export const OptionText = styled.span`
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 5px;
    color: #836bd9;
`;

export const StyledNavbarBrand = styled(Navbar.Brand)`
    font-size: 28px !important;
    font-weight: 700 !important;
    width: 200px;
    font-family: Branding;
    text-align: center;
    color: white !important;
    margin: 0 16px 0 8px;
    padding: 5px 0;

    :hover {
        text-decoration: none;
    }
`;

export const StyledButton = styled(Button)`
    background-color: transparent;
    height: 40px;
    border: none;

    :hover {
        background-color: transparent;
        border: none;
    }
`;

export const StyledAlignDiv = styled.div`
    flex: 1;
`;
