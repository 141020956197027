import projects from '../constants/projects';
import Project from '../shared/interfaces/project.interface';
import userHasRoles from '../utils/userHasRoles';
import { useAuth } from './use-auth';

export default function useAllowedProjects(): Project[] {
    const { user } = useAuth();

    const allowedProjects = projects.filter(
        ({ roles }) => !roles.length || userHasRoles(user, roles),
    );

    return allowedProjects;
}
