import { Col, NavDropdown, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { StyledDropdownTitle, StyledLink } from './styles';
import useNotifications from '../../../../../hooks/useNotification';
import isFriendlyHttpError from '../../../../../utils/isFriendlyHttpError';
import NotificationHttpService from '../../../../../services/http/notification-http';
import confirmDialog from '../../../../../utils/dialog/confirmDialog';

export default function NotificationHeader() {
    const { notificationsQuery } = useNotifications();

    const removeAllNotificationsMutation = useMutation({
        mutationFn: async () => NotificationHttpService.deleteMany(),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao remover as notificações');
        },
        onSettled: () => notificationsQuery.refetch(),
    });

    return (
        <NavDropdown.Header style={{ width: '420px' }}>
            <Row>
                <Col>
                    <StyledDropdownTitle className="text-wrap">
                        Notificações
                    </StyledDropdownTitle>
                </Col>
                {Boolean(notificationsQuery.data?.length) && (
                    <Col className="text-right">
                        <StyledLink
                            onClick={async () => {
                                const confirm: any = await confirmDialog(
                                    'Deseja excluir todas as notificações?',
                                );

                                if (confirm) {
                                    removeAllNotificationsMutation.mutateAsync();
                                }
                            }}
                        >
                            Limpar
                        </StyledLink>
                    </Col>
                )}
            </Row>
        </NavDropdown.Header>
    );
}
