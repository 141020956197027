/* eslint-disable no-else-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { useEffect, useRef, useState } from 'react';
import {
    Accordion,
    Breadcrumb,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
    Spinner,
} from 'react-bootstrap';
import HtmlParser from 'react-html-parser';
import { useInfiniteQuery } from 'react-query';
import { rowsPerPage } from '../../constants/pagination';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import useLibrariesHttpService from '../../services/http/libraries-http';
import {
    ArrowIconDown,
    ArrowIconUp,
    DownloadIcon,
    DownloadText,
} from './style';
import { LibraryTypeReference } from '../../constants/library';
import { formatBytes } from '../../utils/text/getFileSize';
import { handleDownloadFile } from '../../utils/download/downloadFile';
import getItemFileUrl from '../../utils/getItemFileUrl';

interface Props {
    libraryId: number;
    libraryTitle: string;
    libraryDescription: string;
}

export function FAQ(props: Props) {
    const { getItems } = useLibrariesHttpService();

    const [expanded, setExpanded] = useState<number[]>([]);
    const [term, setTerm] = useState('');

    async function loadItems({ pageParam = 0 }: any) {
        const params = {
            term,
            skip: pageParam,
            take: rowsPerPage,
            libraryType: LibraryTypeReference.FAQ,
            libraryId: props.libraryId,
        };

        const { data } = await getItems(params);

        return {
            data: data.data,
            currentPage: pageParam,
            pages: Math.ceil(data.meta.total / 5),
        };
    }

    const loadMoreRef = useRef();

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
        refetch,
    } = useInfiniteQuery('itemsCommercial', loadItems, {
        getNextPageParam: (response: any) => {
            if (response.currentPage + 1 < response.pages) {
                return response.currentPage + 1;
            }
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
        }, 500);

        return () => clearTimeout(timer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    return (
        <Container className={'mt-5 mb-5'}>
            {!isLoading && !isFetchingNextPage && data && (
                <>
                    <Breadcrumb className={'mt-5'}>
                        <Breadcrumb.Item href="/">Biblioteca</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {props.libraryTitle}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Col className={'pl-0 pr-0 mt-5'}>
                        <h1>{props.libraryTitle}</h1>
                        <h6>{props.libraryDescription}</h6>
                    </Col>
                    <InputGroup className="mb-4">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="text"
                            value={term}
                            onChange={(event) => setTerm(event.target.value)}
                            placeholder="Digite aqui o que procura..."
                        />
                    </InputGroup>
                    <Col className={'pl-0 pr-0 pt-2'}>
                        {data?.pages &&
                            data?.pages.map((group: any) =>
                                group?.data.map((item: any, index: any) => (
                                    <Accordion className={'mt-3'} key={index}>
                                        <Card>
                                            <Accordion.Toggle
                                                as={Card.Header}
                                                eventKey={index.toString()}
                                                onClick={() => {
                                                    if (
                                                        expanded.includes(
                                                            item.id,
                                                        )
                                                    ) {
                                                        return setExpanded(
                                                            (
                                                                expand: number[],
                                                            ) =>
                                                                expand.filter(
                                                                    (
                                                                        itemCardId,
                                                                    ) =>
                                                                        itemCardId !==
                                                                        item.id,
                                                                ),
                                                        );
                                                    } else {
                                                        return setExpanded(
                                                            (
                                                                expand: number[],
                                                            ) =>
                                                                expand.concat(
                                                                    item.id,
                                                                ),
                                                        );
                                                    }
                                                }}
                                            >
                                                <Row
                                                    className={
                                                        'p-2 align-items-center'
                                                    }
                                                >
                                                    <Col>
                                                        <h4 className={'mt-2'}>
                                                            {item.title}
                                                        </h4>
                                                        {item.file && (
                                                            <DownloadText
                                                                onClick={async (
                                                                    e,
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    handleDownloadFile(
                                                                        item
                                                                            .file
                                                                            .fileName,
                                                                        getItemFileUrl(
                                                                            item,
                                                                        ),
                                                                    );
                                                                }}
                                                                title={
                                                                    item.file
                                                                        .awsFileName
                                                                }
                                                            >
                                                                <DownloadIcon />
                                                                Baixar arquivo (
                                                                {formatBytes(
                                                                    item.file
                                                                        .fileSize,
                                                                )}
                                                                )
                                                            </DownloadText>
                                                        )}
                                                        <p className="fs-6 text">
                                                            Data de publicação:{' '}
                                                            {new Date(
                                                                item.createdAt,
                                                            ).toLocaleDateString()}
                                                        </p>
                                                    </Col>
                                                    {expanded.includes(
                                                        item.id,
                                                    ) ? (
                                                        <ArrowIconUp />
                                                    ) : (
                                                        <ArrowIconDown />
                                                    )}
                                                </Row>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse
                                                eventKey={index.toString()}
                                            >
                                                <Card.Body
                                                    style={{
                                                        whiteSpace:
                                                            'break-spaces',
                                                    }}
                                                >
                                                    {HtmlParser(
                                                        item.description,
                                                    )}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                )),
                            )}
                    </Col>
                </>
            )}
            <Col md={12} className="text-center" ref={loadMoreRef as any}>
                {(isLoading || isFetchingNextPage) && (
                    <Col md={12} className="text-center">
                        <Spinner
                            animation="border"
                            className="mr-2 mt-1"
                            size={'sm'}
                        />
                        Carregando...
                    </Col>
                )}
                {data?.pages[0].data.length === 0 && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Col>
        </Container>
    );
}
