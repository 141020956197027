import { Col, Dropdown, ListGroup, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import {
    StyledNotificationMessage,
    StyledNotificationTitle,
    StyledDropdownButton,
    StyledLink,
} from './style';
import { ReactComponent as ReactInformationIcon } from '../../../../../assets/images/icons/information.svg';
import { ReactComponent as ReactCancelIcon } from '../../../../../assets/images/icons/cancel.svg';
import Notification from '../../../../../shared/interfaces/notification.interface';
import { ReactComponent as ReactMoreIcon } from '../../../../../assets/images/icons/more.svg';
import useNotifications from '../../../../../hooks/useNotification';
import { NotificationIcon } from '../../../../../constants/notificationIcon';
import { CRM_URL, PORTAL_URL } from '../../../../../config/urls';
import isFriendlyHttpError from '../../../../../utils/isFriendlyHttpError';
import NotificationHttpService from '../../../../../services/http/notification-http';
import { NotificationType } from '../../../../../constants/notificationType';

interface NotificationItemProps {
    notification: Notification;
}

export default function NotificationItem({
    notification,
}: NotificationItemProps) {
    const { notificationsQuery } = useNotifications();
    const notificationHasLink =
        notification.type === NotificationType.Invoice ||
        notification.type === NotificationType.Budget;

    const markAsReadMutation = useMutation({
        mutationFn: async () => NotificationHttpService.update(notification.id),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao atualizar a notificação');
        },
        onSettled: () => notificationsQuery.refetch(),
    });

    const removeMutation = useMutation({
        mutationFn: async () => NotificationHttpService.delete(notification.id),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao remover a notificação');
        },
        onSettled: () => notificationsQuery.refetch(),
    });

    const handleNotificationOpening = async () => {
        await markAsReadMutation.mutateAsync();

        switch (notification.type) {
            case NotificationType.Invoice:
                window.open(
                    `${PORTAL_URL}/pedidos/${notification.invoiceId}`,
                    '_blank',
                );
                break;
            case NotificationType.Budget:
                window.open(
                    `${PORTAL_URL}/orcamentos/${notification.invoiceId}`,
                    '_blank',
                );
                break;
            case NotificationType.SellerRegister:
                window.open(
                    `${CRM_URL}/integrators/${notification.sellerId}/edit`,
                    '_blank',
                );
                break;
            default:
                break;
        }
    };

    return (
        <ListGroup.Item>
            <Row className="w-100">
                <Col sm={2}>
                    {[NotificationIcon.Success, NotificationIcon.Info].includes(
                        notification.icon,
                    ) ? (
                        <ReactInformationIcon
                            fill="#2F80ED"
                            width="40"
                            height="40"
                        />
                    ) : (
                        <ReactCancelIcon
                            fill="#EB5757"
                            width="40"
                            height="40"
                        />
                    )}
                </Col>

                <Col sm={9}>
                    <div className="mt-0 pl-n2 ml-n3">
                        <StyledNotificationTitle className="text-wrap">
                            {notification.title}
                        </StyledNotificationTitle>
                        <StyledNotificationMessage className="text-wrap">
                            {notificationHasLink ? (
                                <StyledLink onClick={handleNotificationOpening}>
                                    {notification.message}
                                </StyledLink>
                            ) : (
                                <span>{notification.message}</span>
                            )}
                        </StyledNotificationMessage>
                    </div>
                </Col>
                <Col sm={1}>
                    <StyledDropdownButton
                        key="left"
                        id="dropdown-button-drop-left"
                        drop="left"
                        title={
                            <ReactMoreIcon
                                fill="#bdbdbd"
                                width="10"
                                height="20"
                            />
                        }
                    >
                        {!notification.readed && (
                            <Dropdown.Item
                                onClick={async () =>
                                    markAsReadMutation.mutateAsync()
                                }
                            >
                                Marcar como lida
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item
                            onClick={async () => removeMutation.mutateAsync()}
                        >
                            Excluir
                        </Dropdown.Item>
                    </StyledDropdownButton>
                </Col>
            </Row>
        </ListGroup.Item>
    );
}
