import { AxiosResponse } from 'axios';
import { AUTH_API_URL } from '../../config/urls';
import Library from '../../shared/interfaces/library.interface';
import useRequest from './request';

interface GetLibrariesResponse extends AxiosResponse {
    data: {
        data: Library[];
    };
}

export default function useLibrariesHttpService() {
    const { get } = useRequest({ baseURL: AUTH_API_URL });

    const uri = 'libraries';

    const getLibraries = async (): Promise<GetLibrariesResponse> => {
        return get(uri);
    };

    const getLibrariesByRoute = async (route: string) => {
        return get(`${uri}/route/${route}`);
    };

    const getItems = async (params: any) => {
        return get(`${uri}/items`, params);
    };

    const getModules = async (params: any) => {
        return get(`${uri}/modules`, params);
    };

    const getBrandsByItemModule = async (id: number, params?: any) => {
        return get(`${uri}/items/modules/${id}/brands`, params);
    };

    const getBrandsCategoriesByItemModule = async (
        id: number,
        params?: any,
    ) => {
        return get(`${uri}/items/modules/${id}/brands/categories`, params);
    };

    const getModulesByLibraryRoute = async (route: string) => {
        return get(`${uri}/modules/route/${route}`);
    };

    return {
        getModulesByLibraryRoute,
        getLibrariesByRoute,
        getLibraries,
        getItems,
        getModules,
        getBrandsByItemModule,
        getBrandsCategoriesByItemModule,
    };
}
