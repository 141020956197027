/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/prefer-default-export */
import { Col, Modal } from 'react-bootstrap';
import HtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player';
import { StyledOutlineButton } from './style';

interface Props {
    show: boolean;
    title: string;
    description: string;
    url: string;
    onHide: () => void;
}

export function VideoModal({ show, title, description, url, onHide }: Props) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size={'lg'}
            // className={'modal-dialog-scrollable'}
            centered
        >
            <Modal.Header>
                <Modal.Title
                    className={'ml-3 mr-3'}
                    id="contained-modal-title-vcenter"
                >
                    {title}
                </Modal.Title>
            </Modal.Header>
            <ReactPlayer
                playing={true}
                width="100%"
                height="17.5vw"
                controls={true}
                url={url}
                style={{
                    backgroundColor: '#000',
                }}
                pip={true}
            />
            <Modal.Body className={'w-100'}>
                <Col>
                    <p style={{ whiteSpace: 'break-spaces' }}>
                        {HtmlParser(description)}
                    </p>
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <StyledOutlineButton onClick={onHide}>
                    Voltar
                </StyledOutlineButton>
            </Modal.Footer>
        </Modal>
    );
}
