/* eslint-disable import/prefer-default-export */
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledOutlineButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    color: #5b4af2 !important;

    :hover,
    :focus {
        background-color: #5b4af2 !important;
        color: #fff !important;
    }

    :active {
        color: #ffffff !important;
    }
`;
