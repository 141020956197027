/* eslint-disable import/prefer-default-export */
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import { AuthProvider } from './hooks/use-auth';
import { NotificationProvider } from './contexts/notificationContext';
import history from './services/history';

export function App() {
    const queryClient = new QueryClient();

    return (
        <>
            <Router history={history}>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <NotificationProvider>
                            <Routes />
                        </NotificationProvider>
                    </AuthProvider>
                    <ToastContainer />
                </QueryClientProvider>
            </Router>
        </>
    );
}
