/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable import/prefer-default-export */
export function handleDownloadFile(name: string, url: string) {
    const xhr = new XMLHttpRequest();

    xhr.responseType = 'blob';

    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            var link: any = document.createElement('a');

            link.download = name;
            link.href = reader.result;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        };
        reader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', url);
    xhr.send();
}
