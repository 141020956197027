import { AUTH_API_URL } from '../../config/urls';
import useRequest from './request';

export default function useAuthHttpService() {
    const { get, post } = useRequest({ baseURL: AUTH_API_URL });

    const uri = 'auth';

    const getUser = async () => {
        return get(`${uri}/user`);
    };

    const logout = async () => {
        return post(`${uri}/logout`);
    };

    return {
        getUser,
        logout,
    };
}
