/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import NavbarHeader from '../Navigation/NavBar';
import Sidebar from '../SideBar';

const BaseLayout: React.FC = ({ children }) => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1439px)' });

    const [showSidebar, setShowSidebar] = useState(false);

    const lockScroll = useCallback(() => {
        document.body.style.overflow = 'hidden';

        if (document.body.clientHeight > window.innerHeight) {
            document.body.style.paddingRight = '17px';
        }
    }, []);

    const unlockScroll = useCallback(() => {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    }, []);

    const handleToggle = () => {
        if (showSidebar) {
            unlockScroll();
        } else {
            lockScroll();
        }
        setShowSidebar((showSidebar) => !showSidebar);
    };

    useEffect(() => {
        if (!isSmallScreen) {
            setShowSidebar(false);
            unlockScroll();
        }
    }, [isSmallScreen, unlockScroll]);

    return (
        <>
            <Container
                fluid
                style={{ overflowY: showSidebar ? 'hidden' : 'visible' }}
            >
                <NavbarHeader
                    isSmallScreen={isSmallScreen}
                    handleToggle={handleToggle}
                />
                {children}
                {showSidebar && <Sidebar onHide={handleToggle} />}
            </Container>

            <br />
        </>
    );
};

export default BaseLayout;
