/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import useLibrariesHttpService from '../../services/http/libraries-http';
import { Loading, PageNotFound, PageRedirect } from './styles';
import BaseLayout from '../../components/BaseLayout';
import { LibraryTypeReference } from '../../constants/library';
import { Documentation } from '../../components/Documentation';
import { FAQ } from '../../components/FAQ';
import { Video } from '../../components/Video';

interface RouteParams {
    route: string;
}

export function Libraries() {
    const { route } = useParams<RouteParams>();
    const { getLibrariesByRoute } = useLibrariesHttpService();

    async function loadLibraries() {
        const { data }: any = await getLibrariesByRoute(route);

        return data;
    }

    const { data, refetch, isLoading, isFetching, isError } = useQuery(
        ['libraries'],
        loadLibraries,
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
            retry: 1,
            retryOnMount: false,
        },
    );

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route]);

    return (
        <BaseLayout>
            {!isError &&
                (isLoading || isFetching ? (
                    <Loading>
                        <Spinner
                            animation="border"
                            className="mr-2"
                            size={'sm'}
                        />
                        Carregando informações...
                    </Loading>
                ) : data.type === LibraryTypeReference.Documentation ? (
                    <Documentation
                        libraryId={data.id}
                        libraryTitle={data.title}
                        libraryDescription={data.description}
                        libraryRoute={data.route}
                    />
                ) : data.type === LibraryTypeReference.Video ? (
                    <Video
                        libraryId={data.id}
                        libraryTitle={data.title}
                        libraryDescription={data.description}
                    />
                ) : (
                    <FAQ
                        libraryId={data.id}
                        libraryTitle={data.title}
                        libraryDescription={data.description}
                    />
                ))}
            {isError && (
                <PageNotFound>
                    <h3>👨‍🔧 Página não encontrada</h3>
                    <PageRedirect href="/">
                        Voltar para Inicio{' '}
                        <i className="fas fa-angle-double-right ml-2"></i>
                    </PageRedirect>
                </PageNotFound>
            )}
        </BaseLayout>
    );
}
