import styled from 'styled-components';

export const ArrowIconUp = styled.i.attrs({
    className: `fas fa-solid fa-chevron-up pr-3 fa-2x`,
})`
    color: #666;
    cursor: pointer;
`;

export const ArrowIconDown = styled.i.attrs({
    className: `fas fa-solid fa-chevron-down pr-3 fa-2x`,
})`
    color: #666;
    cursor: pointer;
`;

export const DownloadIcon = styled.i.attrs({
    className: `fas fa-file-alt pr-1`,
})`
    color: #666;
    cursor: pointer;
`;

export const DownloadText = styled.a`
    color: #666;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        color: #5b4af2;
    }
`;
