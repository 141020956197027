/* eslint-disable object-shorthand */
import swal from 'sweetalert';

export default async function confirmDialog(
    question: string,
    text?: string,
    icon?: string,
) {
    const response: any = await swal({
        title: question,
        text: text,
        icon: icon,
        buttons: ['Cancelar', 'Confirmar'],
    });

    if (response) {
        return true;
    }

    return false;
}
