import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import HtmlParser from 'react-html-parser';
import Library from '../../../shared/interfaces/library.interface';
import {
    StyledCard,
    StyledCardText,
    StyledCardTitle,
    StyledIconArea,
} from './styles';

interface HomeCardProps {
    library: Library;
}

export default function HomeCard({
    library: { route, title, description, icon },
}: HomeCardProps) {
    const history = useHistory();

    const goToLibraryPage = () => history.push(route);

    const iconClass = `${icon} fa-4x`;

    return (
        <StyledCard onClick={goToLibraryPage}>
            <Card.Body>
                {icon && (
                    <StyledIconArea>
                        <i className={iconClass} />
                    </StyledIconArea>
                )}
                <StyledCardTitle>{title}</StyledCardTitle>
                {description && (
                    <StyledCardText>{HtmlParser(description)}</StyledCardText>
                )}
            </Card.Body>
        </StyledCard>
    );
}
