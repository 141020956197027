import styled from 'styled-components';

export const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 93vh;
    user-select: none;
`;

export const PageNotFound = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 93vh;
`;

export const PageRedirect = styled.a`
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        text-decoration: none;
    }
`;
