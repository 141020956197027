/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Switch } from 'react-router-dom';
import { Home } from '../../../pages/Home';
import { Libraries } from '../../../pages/Libraries/List';
import PrivateRoute from '../../components/PrivateRoute';

export const LibrariesRoutesComponent: React.FC = () => (
    <Switch>
        <PrivateRoute path={'/'} component={Home} exact />
        <PrivateRoute path={'/:route'} component={Libraries} />
    </Switch>
);
