/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Accordion, Card, Image } from 'react-bootstrap';
import { FaSolarPanel } from 'react-icons/fa6';
import {
    PiBrowsers,
    PiCaretDown,
    PiCaretLeftBold,
    PiCube,
    PiFilmStrip,
    PiGear,
    PiHandshake,
    PiHeadset,
    PiHouse,
    PiMegaphone,
    PiQuestion,
    PiSignOut,
    PiTruck,
} from 'react-icons/pi';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import userIcon from '../../assets/images/icons/user.svg';
import { useAuth } from '../../hooks/use-auth';
import useLibrariesHttpService from '../../services/http/libraries-http';
import confirmDialog from '../../utils/dialog/confirmDialog';
import {
    StyledCardBody,
    StyledCardHeader,
    StyledContentDiv,
    StyledFillerDiv,
    StyledHeaderDiv,
    StyledListDiv,
    StyledMainDiv,
    StyledOptionDiv,
    StyledUserDiv,
    StyledUsernameDiv,
} from './styles';
import useAllowedProjects from '../../hooks/useAllowedProjects';

interface SidebarProps {
    onHide: () => void;
}

export default function Sidebar({ onHide }: SidebarProps) {
    const { user, logout } = useAuth();

    const history = useHistory();

    const { getLibraries } = useLibrariesHttpService();

    const avatar = user.avatar
        ? Buffer.from(user.avatar, 'base64').toString('ascii')
        : '';

    const getIcon = (iconName?: string) => {
        if (!iconName) return <PiQuestion />;

        return (
            {
                'fas fa-home': <PiHouse />,
                'fas fa-bullhorn': <PiMegaphone />,
                'fas fa-headset': <PiHeadset />,
                'fas fa-film': <PiFilmStrip />,
                'fas fa-handshake': <PiHandshake />,
                'far fa-window-restore': <PiBrowsers />,
                FaSolarPanel: <FaSolarPanel />,
                PiGear: <PiGear />,
                PiTruck: <PiTruck />,
                'fas fa-shipping-fast': <PiTruck />,
            }[iconName] || <PiQuestion />
        );
    };

    const goToPath = (path: string) => {
        history.push(path);
        onHide();
    };

    async function loadLibraries() {
        const response = await getLibraries();

        return response.data.data;
    }

    const menu = useQuery(['librariesSidebar'], loadLibraries, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        retry: 1,
        retryOnMount: false,
    });

    const allowedProjects = useAllowedProjects();

    return (
        <StyledMainDiv>
            <StyledContentDiv>
                <StyledHeaderDiv>
                    <span onClick={() => goToPath('/')}>nexen</span>
                    <PiCaretLeftBold onClick={onHide} />
                </StyledHeaderDiv>

                <StyledUserDiv>
                    <Image
                        className="avatar-border"
                        src={avatar || userIcon}
                        height="63px"
                        roundedCircle
                        style={{ borderColor: '#545454' }}
                    />
                    <StyledUsernameDiv>
                        <p>
                            <span>{user.name}</span>
                        </p>
                        <p>
                            <span>{user.email}</span>
                        </p>
                    </StyledUsernameDiv>
                </StyledUserDiv>

                <hr />

                <Accordion>
                    <Card style={{ border: 'none' }}>
                        <Accordion.Toggle
                            as={StyledCardHeader}
                            variant="link"
                            eventKey="0"
                        >
                            <PiCube size={24} />
                            <span>Módulos</span>
                            <PiCaretDown
                                size={24}
                                style={{ marginLeft: 'auto' }}
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <StyledCardBody>
                                {allowedProjects.map(
                                    ({ url, piIcon, name }) => (
                                        <a
                                            key={url}
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {getIcon(piIcon)}
                                            <span>{name}</span>
                                        </a>
                                    ),
                                )}
                            </StyledCardBody>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <hr />

                <StyledListDiv>
                    {!menu.isLoading && menu.data && (
                        <>
                            <StyledOptionDiv onClick={() => goToPath('/')}>
                                {getIcon('fas fa-home')}
                                <span>Inicio</span>
                            </StyledOptionDiv>
                            {menu.data.map((library, index) => (
                                <StyledOptionDiv
                                    key={index}
                                    onClick={() => goToPath(library.route)}
                                >
                                    {getIcon(library.icon)}
                                    <span>{library.title}</span>
                                </StyledOptionDiv>
                            ))}
                        </>
                    )}
                </StyledListDiv>

                <StyledOptionDiv
                    onClick={async () => {
                        const confirm: any = await confirmDialog(
                            'Deseja realmente sair?',
                        );

                        if (!confirm) {
                            return;
                        }

                        logout();
                    }}
                    style={{ marginTop: 'auto' }}
                >
                    <PiSignOut />
                    <span>Sair</span>
                </StyledOptionDiv>
            </StyledContentDiv>

            <StyledFillerDiv onClick={onHide} />
        </StyledMainDiv>
    );
}
