import React from 'react';
import { Image, NavDropdown, Col, Row } from 'react-bootstrap';
import {
    StyledDropdownButton,
    StyledNavDropdownItem,
    StyledDropdownTitle,
    StyledDropdownSubtitle,
    StyledTextRole,
} from '../styles';

import { ReactComponent as ReactProfileIcon } from '../../../../assets/images/icons/profile.svg';
import { ReactComponent as ReactLogoutIcon } from '../../../../assets/images/icons/logout.svg';

import { useAuth } from '../../../../hooks/use-auth';
import { getRolesNames } from '../../../../utils/text/getRolesNames';
import confirmDialog from '../../../../utils/dialog/confirmDialog';

const UserDropdownMenu: React.FC = () => {
    const { user, logout } = useAuth();
    const avatar = user.avatar
        ? Buffer.from(user.avatar, 'base64').toString('ascii')
        : '';

    return (
        <>
            <StyledDropdownButton
                className="mr-1"
                size="lg"
                menuAlign={{ lg: 'right' }}
                title={
                    <>
                        {avatar ? (
                            <Image
                                className="mt-n2 mr-n3 avatar-border"
                                width="48"
                                src={avatar}
                                roundedCircle
                            />
                        ) : (
                            <ReactProfileIcon
                                className="mt-n1 mr-n3"
                                width={38}
                                height={38}
                                fill="#ffffff"
                            />
                        )}
                    </>
                }
            >
                <NavDropdown.Header style={{ width: '400px' }}>
                    <Row className="align-items-center ma-0 pa-0">
                        <Col md={2} className="text-left">
                            {avatar ? (
                                <Image
                                    className="cursor-pointer"
                                    width="60"
                                    src={avatar}
                                    roundedCircle
                                />
                            ) : (
                                <ReactProfileIcon
                                    className="cursor-pointer"
                                    width={60}
                                    height={60}
                                    fill="#cccccc"
                                />
                            )}
                        </Col>
                        <Col md={10}>
                            <StyledDropdownTitle className="d-block text-wrap pl-2">
                                {user.name}
                            </StyledDropdownTitle>
                            <StyledDropdownSubtitle className="d-block text-wrap pl-2">
                                {user.email}
                            </StyledDropdownSubtitle>
                            <StyledTextRole className="text-wrap pl-2">
                                {getRolesNames(user.roles)}
                            </StyledTextRole>
                        </Col>
                    </Row>
                </NavDropdown.Header>

                <NavDropdown.Divider />

                <StyledNavDropdownItem
                    onClick={async () => {
                        const confirm: any = await confirmDialog(
                            'Deseja realmente sair?',
                        );

                        if (!confirm) {
                            return;
                        }

                        logout();
                    }}
                >
                    <ReactLogoutIcon
                        width={20}
                        height={20}
                        fill="#707070"
                        className="mr-2"
                    />
                    Sair
                </StyledNavDropdownItem>
            </StyledDropdownButton>
        </>
    );
};

export default UserDropdownMenu;
