import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    border-radius: 0.25rem;
    width: 100%;
    background: white;
    transition: all 0.3s ease-in-out;

    :hover {
        filter: brightness(0.95);
    }
`;

export const StyledIconArea = styled.p`
    text-align: center;
    color: #5b4af2;
`;

export const StyledCardTitle = styled(Card.Title)`
    text-align: center;
`;

export const StyledCardText = styled(Card.Text)`
    font-size: 0.75rem !important;
    white-space: break-spaces;
    text-align: center;
`;
