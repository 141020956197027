import { AUTH_API_URL } from '../../config/urls';
import useRequest from './request';

export default class NotificationHttpService {
    private static request = useRequest({ baseURL: AUTH_API_URL });

    private static uri = 'notifications';

    public static async readMany() {
        return this.request.get(`${this.uri}`);
    }

    public static async update(id: number) {
        return this.request.patch(`${this.uri}/${id}`);
    }

    public static async delete(id: number) {
        return this.request.del(`${this.uri}/${id}`);
    }

    public static async deleteMany() {
        return this.request.del(`${this.uri}/all`);
    }
}
