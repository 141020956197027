import { Badge } from 'react-bootstrap';
import { ReactComponent as ReactNotifyIcon } from '../../../../../assets/images/icons/notify.svg';

interface NotificationIconProps {
    unreadNotificationsAmount: number;
}

export default function NotificationIcon({
    unreadNotificationsAmount,
}: NotificationIconProps) {
    return (
        <>
            <ReactNotifyIcon width={30} height={30} fill="white" />
            <sup className="superscript">
                <Badge className="ml-n2 notify-badge" variant="primary">
                    {unreadNotificationsAmount}
                </Badge>
            </sup>
        </>
    );
}
