import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { LibrariesRoutesComponent } from './config';

const AppRoutes: React.FC = () => (
    <Switch>
        <Route component={LibrariesRoutesComponent} />
    </Switch>
);

export default AppRoutes;
