/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    useState,
    createContext,
    useContext,
    useEffect,
    useReducer,
} from 'react';
import { IAuthInitialState, IUseAuth } from './interfaces';
import useAuthHttpService from '../../services/http/auth-http';
import ActionType from './enums/action-type-enum';
import authReducer from './reducers/auth-reducer';
import { CRM_URL } from '../../config/urls';

const useProvideAuth = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { getUser, logout: logoutUser } = useAuthHttpService();

    const initialState: IAuthInitialState = {
        isAuthenticated: true,
        user: {},
    };

    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        const check = async () => {
            setIsLoading(true);
            try {
                const { data: user } = await getUser();
                dispatch({ type: ActionType.Login, payload: { user } });
            } catch (error) {
                dispatch({ type: ActionType.Logout, payload: {} });
            }
            setIsLoading(false);
        };

        check();
    }, []);

    const logout = async () => {
        await logoutUser();
        window.location.replace(CRM_URL ?? '');
    };

    return { logout, isLoading, ...state } as IUseAuth;
};

const AuthContext = createContext<IUseAuth>({} as IUseAuth);
const useAuth = () => useContext(AuthContext);

function AuthProvider(props: any) {
    const auth = useProvideAuth();

    return <AuthContext.Provider value={auth} {...props} />;
}

export { AuthProvider, useAuth };
