/* eslint-disable react/jsx-curly-brace-presence */
import {
    Col,
    Image,
    Nav,
    Navbar,
    OverlayTrigger,
    Popover,
    Row,
} from 'react-bootstrap';
import { PiList } from 'react-icons/pi';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import useLibrariesHttpService from '../../../services/http/libraries-http';
import apps from '../../../assets/images/icons/apps.svg';

import UserDropdownMenu from './UserDropdownMenu';
import {
    Icon,
    IconContainer,
    OptionContainer,
    OptionText,
    StyledAlignDiv,
    StyledButton,
    StyledNavbar,
    StyledNavbarBrand,
    StyledNavbarItemDivider,
} from './styles';
import useAllowedProjects from '../../../hooks/useAllowedProjects';
import NotificationsDropdown from './NotificationsDropdownMenu';

interface NavbarHeaderProps {
    isSmallScreen: boolean;
    handleToggle: () => void;
}

export default function NavBarHeader({
    isSmallScreen,
    handleToggle,
}: NavbarHeaderProps) {
    const { getLibraries } = useLibrariesHttpService();

    const decodedPathname = decodeURIComponent(window.location.pathname);

    async function loadLibraries() {
        const response = await getLibraries();

        return response.data.data;
    }

    const librariesQuery = useQuery(['librariesNavbar'], loadLibraries, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        retry: 1,
        retryOnMount: false,
    });

    const showLibraries =
        !librariesQuery.isLoading &&
        !librariesQuery.isFetching &&
        librariesQuery.data;

    const allowedProjects = useAllowedProjects();

    if (isSmallScreen) {
        return (
            <>
                <StyledNavbar
                    bg="dark"
                    variant="dark"
                    className="ma-0 pa-0 d-flex justify-content-between"
                >
                    <StyledAlignDiv>
                        <StyledButton onClick={handleToggle}>
                            <PiList size={30} />
                        </StyledButton>
                    </StyledAlignDiv>

                    <StyledNavbarBrand as={Link} to={'/'} className="ml-2">
                        nexen
                    </StyledNavbarBrand>

                    <StyledAlignDiv />
                </StyledNavbar>
            </>
        );
    }

    return (
        <>
            <StyledNavbar
                expand="lg"
                bg="dark"
                variant="dark"
                className="ma-0 pa-0"
            >
                <Navbar.Brand
                    as={Link}
                    to={'/'}
                    style={{
                        fontSize: '28px',
                        fontWeight: 700,
                        width: '200px',
                        fontFamily: 'Branding',
                    }}
                    className="ml-2 d-block"
                >
                    nexen
                </Navbar.Brand>

                {showLibraries && (
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link
                                as={Link}
                                to="/"
                                className="text-center"
                                style={{
                                    paddingInline: '1.5rem',
                                    color: 'white',
                                    backgroundColor:
                                        decodedPathname === '/'
                                            ? 'rgba(255, 255, 255, 0.18)'
                                            : undefined,
                                }}
                            >
                                <i className="fas fa-home fa-2x" />
                                <span className="d-block">Inicio</span>
                            </Nav.Link>
                            {librariesQuery.data.map(
                                ({ route, title, icon }) => (
                                    <Nav.Link
                                        as={Link}
                                        key={route}
                                        to={route}
                                        className="text-center"
                                        style={{
                                            paddingInline: '1.5rem',
                                            color: 'white',
                                            backgroundColor:
                                                decodedPathname === `/${route}`
                                                    ? 'rgba(255, 255, 255, 0.18)'
                                                    : undefined,
                                        }}
                                    >
                                        <i className={`${icon} fa-2x`} />
                                        <span className="d-block">{title}</span>
                                    </Nav.Link>
                                ),
                            )}
                        </Nav>
                        <Nav className="pa-0 ma-0">
                            <NotificationsDropdown />
                            <StyledNavbarItemDivider className="ml-3 mr-3" />
                            <OverlayTrigger
                                trigger={'click'}
                                placement={'bottom'}
                                rootClose
                                overlay={
                                    <Popover
                                        id="popover-contained"
                                        className={'p-3 w-300'}
                                        style={{ maxWidth: '420px' }}
                                    >
                                        <Col>
                                            <Row>
                                                {allowedProjects.map(
                                                    ({ url, icon, name }) => {
                                                        return (
                                                            <OptionContainer
                                                                href={url}
                                                                key={url}
                                                            >
                                                                <IconContainer>
                                                                    <Icon
                                                                        className={
                                                                            icon
                                                                        }
                                                                    />
                                                                </IconContainer>
                                                                <OptionText>
                                                                    {name}
                                                                </OptionText>
                                                            </OptionContainer>
                                                        );
                                                    },
                                                )}
                                            </Row>
                                        </Col>
                                    </Popover>
                                }
                            >
                                <Image
                                    style={{ cursor: 'pointer' }}
                                    src={apps}
                                    width={30}
                                    className={'mr-2 ml-2'}
                                />
                            </OverlayTrigger>
                            <UserDropdownMenu />
                        </Nav>
                    </Navbar.Collapse>
                )}
            </StyledNavbar>
        </>
    );
}
